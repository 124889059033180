import { ResourceCategory, ResourceCollection } from '@/common/types/resource.types';

const deepDeleteKeysInObject = function(object:Record<string,any>,delKeys:string[]){
    for (const key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
            const value = object[key];
            if(Array.isArray(value) && typeof value[0] == 'object'){
                value.forEach(el => deepDeleteKeysInObject(el,delKeys) );
            }
            else if(typeof value == 'object') deepDeleteKeysInObject(value,delKeys);
            if(delKeys.includes(key)) delete object[key];
        }
    }
    return object;
}

export default function adminImportResFromObj(importData:Record<string,any>){
    const resData = {
        resourceCategories: [] as ResourceCategory[],
        resourceCollections: [] as ResourceCollection[]
    };
    const {categories,resourceCollections} = importData as {categories: ResourceCategory[],resourceCollections:ResourceCollection[]};
    if(categories == undefined) throw new Error('Resource categories are not present');
    if(resourceCollections == undefined) throw new Error('Resource collections are not present');
    const collectionWithResource = resourceCollections.find((col)=>col.resourceList&&col.resourceList.length);
    if(!collectionWithResource) throw new Error('No resource collection with resource found');
    if(!categories || !categories.length) throw new Error('No resource categories found');
    const processed = deepDeleteKeysInObject( importData, ['id','watchedIds','likedIds','favoriteIds'] );
    resData.resourceCategories = processed.categories;
    resData.resourceCollections = processed.resourceCollections;
    return resData;
}