































































































import Vue from 'vue';
import {Resource, ResourceCollection, ResourceCategory} from '@/common/types/resource.types';
import Collapsible from '@/components/elements/Collapsible.vue';
import ApiService from '@/common/api.service';
import {endpoints} from '@/common/config';
import { downloadFile, promiseFileSelect } from '@/common/misc';
import adminImportResFromObj from '@/common/admin/admin.resource.import';
import ResourceEditForm from '@/components/elements/ResourceEditForm.vue';
import Draggable from 'vuedraggable';

export default Vue.extend({
    name: 'adminResourcesTab',
    data(){
        const states = Object.freeze({
            FETCHING_RESOURCES: 0,
            DEFAULT: 1
        })
        return{
            state: states.FETCHING_RESOURCES,
            states,
            showFetchOverlay: false,
            collapseEditCategory: false,
            collapseActiveResource: false,
            resourceCollections: [] as Array<ResourceCollection>,
            //Resource OR ResourceCollection
            activeResource: {} as (Resource|ResourceCollection),
            activeResourceParentCollection: undefined as ResourceCollection|undefined,
            resourceCategories: [] as Array<ResourceCategory>,
            persistedResourceCategories: [] as Array<ResourceCategory>
        }
    },
    components: {
        Collapsible,
        Draggable,
        ResourceEditForm
    },
    async mounted(){
        this.state = this.states.FETCHING_RESOURCES;

        await this.fetchResourceCategoriesAndCollections();

        this.state = this.states.DEFAULT;
    },
    methods: {
        async importResources(){
            try{
                let fileSelectCancel = false;
                const selectedFile = (await promiseFileSelect().catch(()=>fileSelectCancel = true)) as any;
                if(fileSelectCancel) return;
                const fr = new FileReader();
                fr.readAsText(selectedFile);
                const text = (await new Promise((res)=>{ 
                    fr.onload = () => res(fr.result as string);
                }) ) as string;
                const imported = adminImportResFromObj( JSON.parse(text) );
                await ApiService.post(endpoints.adminImportResources,imported);
                location.reload(true);
            // eslint-disable-next-line no-empty
            }catch(e){
                console.error(e);
                //Проверьте консоль разработчика (F12/Ctrl+Shift+J/Cmd+Opt+J)
                alert('При импорте произошла ошибка.'+(e||''));
            }
        },
        async fetchResourceCategoriesAndCollections(){
            let {data} = await ApiService.get(endpoints.adminGetResourceCollection);
            this.resourceCollections = data.resourceCollections;
            this.resourceCategories = 
                (await ApiService.get(endpoints.adminGetResourceCategories))
                    .data
                    .resourceCategories;
            this.resourceCategories = this.resourceCategories.sort((a,b)=>a.priority-b.priority);
            this.setPersistedResourceCategories(this.resourceCategories);
            this.activeResource = {} as any;
        },
        async createResourceCollection(name:string):Promise<void>{
            if(name.trim().length==0) return;
            this.onFetchStart();
            const {data} = await ApiService.post(endpoints.adminSaveResourceCollection, {name});
            this.onFetchEnd();
            const {resourceCollection} = data as {resourceCollection:ResourceCollection};
            this.resourceCollections.push(resourceCollection);
        },
        //#region resource categories
        async createResourceCategory(text:string):Promise<void>{
            if(text.trim().length==0) return;
            if(this.resourceCategories.find((rc)=>rc.text == text)) return;
            this.resourceCategories.push({text,priority:this.resourceCategories.length});
        },
        deleteResourceCategory(index:number):void{
            this.resourceCategories = this.resourceCategories.filter((r,i)=>i!=index);
        },
        renameResourceCategory(index:number):void{
            const category = this.resourceCategories[index];
            const newName = prompt('Введите новое название категори',category.text);
            if(!newName) return;
            this.$set( category, 'text', newName);
        },
        async saveResourceCategories():Promise<void>{
            const clonedCategories = this.resourceCategories.map((rc,i)=>{
                const clone=Object.assign({},rc); 
                clone.priority = i; 
                return clone;
            });
            this.onFetchStart();
            const {data} = await ApiService.post(endpoints.adminSaveResourceCategories, clonedCategories);
            await this.fetchResourceCategoriesAndCollections();
            this.onFetchEnd();
        },
        setPersistedResourceCategories(resourceCategories:ResourceCategory[]){
            this.persistedResourceCategories = [...resourceCategories.map((rc)=>Object.assign({},rc))];
        },
        //#endregion
        async addResourceToCollection(collection:ResourceCollection,name:string){
            if(name.trim().length==0) return;
            this.onFetchStart();
            const {data} = await ApiService.post(endpoints.adminAddResourceToCollection(collection.id),{name})
            this.onFetchEnd();
            const {resourceCollection} = data as {resourceCollection:ResourceCollection};
            this._updateCollectionById(resourceCollection);
        },
        async saveActiveResourceChanges(){
            if(this.isEditingResource){
                const parentCollection = this.activeResourceParentCollection as ResourceCollection;
                this.onFetchStart();
                const {data} = await ApiService.post(endpoints.adminAddResourceToCollection(parentCollection.id),this.activeResource);
                this.onFetchEnd();
                const {resourceCollection} = data;
                this._updateCollectionById(resourceCollection);
            }else if(this.isEditingResourceCollection){
                this.onFetchStart();
                const {data} = await ApiService.post(endpoints.adminSaveResourceCollection,this.activeResource);
                this.onFetchEnd();
                const {resourceCollection} = data;
                this._updateCollectionById(resourceCollection);
            }else return;
        },
        async deleteResourceFromCollection(collection:ResourceCollection,resource:Resource){
            if(!confirm(`Вы уверены, что хотите удалить ресурс '${resource.name}' из коллекции '${collection.name}'?`)) return;
            this.onFetchStart();
            const {data} = await ApiService.delete(endpoints.adminDeleteResourceFromCollection(collection.id,resource.id));
            this.onFetchEnd();
            const {resourceCollection} = data as {resourceCollection:ResourceCollection};
            this._updateCollectionById(resourceCollection);
            if(collection.id == this.activeResource.id) this.activeResource = {} as ResourceCollection|Resource;
        },
        async deleteResourceCollection(collection:ResourceCollection){
            const {id} = collection;
            if(!confirm(`Вы уверены, что хотите удалить коллекцию ресурсов '${collection.name}'?`)) return;
            this.onFetchStart();
            await ApiService.delete(endpoints.adminDeleteResourceCollection(id));
            this.onFetchEnd();
            this.resourceCollections = this.resourceCollections.filter((rc)=>rc.id!=id)
            if(collection.id == this.activeResource.id) this.activeResource = {} as ResourceCollection|Resource;
        },
        onFetchStart(){
            this.showFetchOverlay = true;
        },
        onFetchEnd(){
            this.showFetchOverlay = false;
        },
        sortResourceList(resources:Resource[]):Resource[]{ return [...resources].sort((a,b)=>a.id-b.id); },
        isResourceActive(resource:(Resource|ResourceCollection)):boolean{
            const typeMismatch = ( this.isEditingResourceCollection && !this.isResourceCollection( resource ) )
                || ( this.isEditingResource && this.isResourceCollection(resource) );
            return this.activeResourceIsPresent && !typeMismatch && resource.id == this.activeResource.id;
        },
        isResourceCollection(resource:any){
            return resource && typeof resource == 'object' && Array.isArray( resource.resourceList );
        },
        setActiveResource(resource:(Resource|ResourceCollection)){
            if(!this.isResourceCollection(resource)){ 
                this.activeResourceParentCollection = this.resourceCollections.find((rc)=>rc.resourceList.find((r)=>r.id==resource.id));
                if(!this.activeResourceParentCollection) throw new Error(`Unable to find resource collection with '${resource.name}' resource.`);
            }
            this.activeResource = Object.assign({},resource);
        },
        loadJson():void{
            const filename = `IziBiz-resource-export(${new Date().getTime()}).json`;
            downloadFile(JSON.stringify({resourceCollections:this.resourceCollections,categories:this.resourceCategories}),filename,'text/plain');
        },
        _updateCollectionById(collection:ResourceCollection){
            const index = this.resourceCollections.findIndex((rc)=>rc.id==collection.id);
            this.$set(this.resourceCollections, index, collection);
        }
    },
    computed: {
        activeResourceCategory():number|ResourceCategory|undefined{
            return this.activeResourceIsPresent ? (this.activeResource as any).category : undefined;
        },
        isDefaultState():boolean{
            return this.state == this.states.DEFAULT;
        },
        isFetchingResourcesState():boolean{
            return this.state == this.states.FETCHING_RESOURCES;
        },
        activeResourceIsPresent():boolean{
            return Object.keys( this.activeResource ).length!=0;
        },
        sortedResourceCollections():ResourceCollection[]{
            return [...this.resourceCollections].sort((a,b)=>a.id-b.id);
        },
        //?Find a better way of checking type
        isEditingResource():boolean{
            return this.activeResourceIsPresent && !this.isEditingResourceCollection;
        },
        //?Find a better way of checking type
        isEditingResourceCollection():boolean{
            return this.activeResourceIsPresent && this.isResourceCollection(this.activeResource);
        }
    }
})
